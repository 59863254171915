import { useCallback, useContext, useState } from "react";
import Context from "../context/userContext";
import { Login } from "../service/login";
import Auth from "../utils/validateToken";

export default function useUser() {
  const { cookies, setCookie, removeCookie } = useContext(Context);
  const [state, setstate] = useState({
    loading: false,
    error: false,
    message: "",
  });

  const login = useCallback(
    ({ email, password }) => {
      setstate({ loading: true, error: false, message: "" });
      Login({ email, password })
        .then((response) => {
          if (response.success) {
            setstate({ loading: false, error: false, message: "" });
            const token = Auth.encode(response);
            setCookie("jwt", token, { sameSite: true });
          } else {
            setstate({
              loading: false,
              error: true,
              message: response.message,
            });
            removeCookie("jwt");
          }
        })
        .catch((err) => {
          setstate({ loading: false, error: true, message: err });
          removeCookie("jwt");
        });
    },
    [removeCookie, setCookie]
  );

  const logout = useCallback(() => {
    removeCookie("jwt");
  }, [removeCookie]);

  const verificationUser = () => {
    const jwt = Auth.decode(cookies.jwt);
    if (jwt) {
      return true;
    } else {
      return false;
    }
  };

  return {
    isLoggedIn: Auth.decode(cookies.jwt),
    user: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt).username : "",
    user_id: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt) : "",
    rol: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt).rol : "",
    token: Auth.decode(cookies.jwt)
      ? Auth.decode(cookies.jwt).token_type +
        " " +
        Auth.decode(cookies.jwt).access_token
      : undefined,
    loading: state.loading,
    error: state.error,
    message: state.message,
    login,
    logout,
    verificationUser,
  };
}