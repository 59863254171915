/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useContacto from "../../hooks/useContacto";
import useLogin from "../../hooks/useLogin";
import Swal from "sweetalert2";
import { Contacto } from "../../service/contacto";

const options = [
  {
    value: "FESTIVAL INTERNACIONAL DE CINE DE CARTAGENA DE INDIAS",
    label: "FESTIVAL INTERNACIONAL DE CINE DE CARTAGENA DE INDIAS",
  },
  {
    value: "FICUNAM FESTIVAL INTERNACIONAL DE CINE DE LA UNAM",
    label: "FICUNAM FESTIVAL INTERNACIONAL DE CINE DE LA UNAM",
  },
  {
    value: "AMBULANTE CINE DOCUMENTAL ITINERANTE",
    label: "AMBULANTE CINE DOCUMENTAL ITINERANTE",
  },
  { value: "SOUND CHECK EXPO", label: "SOUND CHECK EXPO" },
  { value: "FESTIVAL MARVIN CDMX", label: "FESTIVAL MARVIN CDMX" },
  {
    value: "FESTIVAL INTERNACIONAL DE CINE EN GUADALAJARA",
    label: "FESTIVAL INTERNACIONAL DE CINE EN GUADALAJARA",
  },
  {
    value: "BAM BOGOTA AUDIOVISUAL MARKET",
    label: "BAM BOGOTA AUDIOVISUAL MARKET",
  },
  { value: "IBTM AMERICAS", label: "IBTM AMERICAS" },
  {
    value: "DOQUMENTA, Documental en Queretaro A.C.",
    label: "DOQUMENTA, Documental en Queretaro A.C.",
  },
  {
    value:
      "MACABRO FESTIVAL INTERNACIONAL DE CINE DE HORROR DE LA CIUDAD DE MÉXICO",
    label:
      "MACABRO FESTIVAL INTERNACIONAL DE CINE DE HORROR DE LA CIUDAD DE MÉXICO",
  },
  {
    value: "FUNDACION FESTIVAL INTERNACIONAL DE CINE EN LAS MONTAÑAS",
    label: "FUNDACION FESTIVAL INTERNACIONAL DE CINE EN LAS MONTAÑAS",
  },
  { value: "PREMIOS ARIEL", label: "PREMIOS ARIEL" },
  {
    value: "SHORTS MEXICO- FESTIVAL INTERNACIONAL DE CORTOMETRAJES ",
    label: "SHORTS MEXICO- FESTIVAL INTERNACIONAL DE CORTOMETRAJES ",
  },
  {
    value:
      "MICGENERO- MUESTRA INTERNACIONAL DE CINE CON PRESPECTIVA DE GENERO ",
    label:
      "MICGENERO- MUESTRA INTERNACIONAL DE CINE CON PRESPECTIVA DE GENERO ",
  },
  {
    value: "GUANAJUATO INTERNATIONAL FILM FESTIVAL",
    label: "GUANAJUATO INTERNATIONAL FILM FESTIVAL",
  },
  {
    value: "FERATUM, FESTIVAL INTERNACIONAL DE CINE FANTÁSTICO A.C.",
    label: "FERATUM, FESTIVAL INTERNACIONAL DE CINE FANTÁSTICO A.C.",
  },
  {
    value: "FESTIVAL INTERNACIONAL DE CINE EN MORELIA",
    label: "FESTIVAL INTERNACIONAL DE CINE EN MORELIA",
  },
  { value: "FESTIVAL DOCSMX", label: "FESTIVAL DOCSMX" },
  {
    value: "CUTOUT FEST- FESTIVAL INTERNACIONAL DE ANIMACIÓN Y ARTE DIGITAL",
    label: "CUTOUT FEST- FESTIVAL INTERNACIONAL DE ANIMACIÓN Y ARTE DIGITAL",
  },
  {
    value: "FESTIVAL INTERNACIONAL DE CINE DE CALI",
    label: "FESTIVAL INTERNACIONAL DE CINE DE CALI",
  },
  { value: "FICMY MERIDA Y YUCATAN", label: "FICMY MERIDA Y YUCATAN" },
  {
    value: "MICMX MUESTRA ITINERANTE DE CINE MX",
    label: "MICMX MUESTRA ITINERANTE DE CINE MX",
  },
  {
    value: "FICMA FESTIVAL INTERNACIONAL DE CINE CON MEDIOS ALTERNATIVOS",
    label: "FICMA FESTIVAL INTERNACIONAL DE CINE CON MEDIOS ALTERNATIVOS",
  },
  { value: "48 HOUR FILM PROJECT", label: "48 HOUR FILM PROJECT" },
  { value: "FESTIVAL DEL PUERTO ", label: "FESTIVAL DEL PUERTO " },
  { value: "MUTEK MÉXICO", label: "MUTEK MÉXICO" },
];

const loginSchema = Yup.object().shape({
  Nombre: Yup.string().required("El nombre es obligatorio"),
  ApellidoPaterno: Yup.string().required("El apellido paterno es obligatorio"),
  ApellidoMaterno: Yup.string().required("El apellido materno es obligatorio"),
  Genero: Yup.string().required("El genero es obligatorio"),
  TipoContacto: Yup.string().required("El tipo de contacto es obligatorio"),
  TelMovil: Yup.string().required("El teléfono móvil es obligatorio"),
  TelFijo: Yup.string().required("El teléfono fijo es obligatorio"),
  Correo: Yup.string().required("El correo es obligatorio"),
  Empresa: Yup.string().required("La empresa es obligatoria"),
  TipoEmpresa: Yup.string().required("El tipo de empresa es obligatorio"),
  Giro: Yup.string().required("El giro es obligatorio"),
  Cargo: Yup.string().required("El cargo es obligatorio"),
  Comentarios: Yup.string().required("Los comentarios son obligatorios"),
});

export default function Index() {
  const navigate = useNavigate();
  const { error, contacto, message, success } = useContacto();
  const { user_id, logout } = useLogin();
  const [total, settotal] = useState(0);
  const [tipo, settipo] = useState(0);
  const [internetValidato, setinternetValidato] = useState(false);

  useEffect(() => {
    getInformacion();
    return () => {};
  }, []);

  const closeSession = () => {
    logout();
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const validadorInternet = await fetch(
        "https://jsonplaceholder.typicode.com/todos/1"
      )
        .then((response) => response.json())
        .then((json) => true)
        .catch(({ message }) => {
          if (message === "Failed to fetch") {
            return false;
          }
        });

      setinternetValidato(validadorInternet);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const getInformacion = () => {
    const valores = localStorage.getItem("informacion_cuestionario");

    if (valores) {
      settotal(JSON.parse(valores).length);
    } else {
      settotal(0);
    }
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        title: "Contacto",
        text: "Contacto guardado con exito",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [error, message]);

  const SendInformacion = async () => {
    const historial = localStorage.getItem("informacion_cuestionario");
    Swal.fire({
      title: "Espere",
      text: "Guardando contacto",
      imageUrl: "logo-login.png",
      imageWidth: 500,
      imageHeight: 100,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal-loader",
      },
    });
    if (historial) {
      const validadorInternet = await fetch(
        "https://jsonplaceholder.typicode.com/todos/1"
      )
        .then((response) => response.json())
        .then((json) => true)
        .catch(({ message }) => {
          if (message === "Failed to fetch") {
            return false;
          }
        });

      if (validadorInternet) {
        const historialArray = JSON.parse(historial);
        Promise.all(
          historialArray.map((item) =>
            Contacto({
              Nombre: item.Nombre,
              ApellidoPaterno: item.ApellidoPaterno,
              ApellidoMaterno: item.ApellidoMaterno,
              Genero: item.Genero,
              TipoContacto: item.TipoContacto,
              Otro: item.Otro,
              TelMovil: item.TelMovil,
              TelFijo: item.TelFijo,
              Correo: item.Correo,
              Empresa: item.Empresa,
              TipoEmpresa: item.TipoEmpresa,
              Otro_TipoEmpresa: item.Otro_TipoEmpresa,
              Giro: item.Giro,
              Cargo: item.Cargo,
              Comentarios: item.Comentarios,
              Festival: item.Festival,
              User: user_id.data.id,
            }).then((response) => {
              return response;
            })
          )
        ).then(() => {
          localStorage.removeItem("informacion_cuestionario");
          Swal.close();
          Swal.fire({
            title: "Contacto",
            text: "Contacto guardado con exito",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "No hay conexión a internet",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.fire({
        title: "No hay información",
        text: "No hay prospectos guardamos localmente",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          Nombre: "",
          ApellidoPaterno: "",
          ApellidoMaterno: "",
          Genero: "",
          TipoContacto: "",
          Otro: "",
          TelMovil: "",
          TelFijo: "",
          Correo: "",
          Empresa: "",
          TipoEmpresa: "",
          Otro_TipoEmpresa: "",
          Giro: "",
          Cargo: "",
          Comentarios: "",
          Festival: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(values, { resetForm }) => {
          if (tipo === 0) {
            const historial = localStorage.getItem("informacion_cuestionario");
            if (historial) {
              const historialArray = JSON.parse(historial);
              historialArray.push(values);
              localStorage.setItem(
                "informacion_cuestionario",
                JSON.stringify(historialArray)
              );

              resetForm({});
              getInformacion();
            } else {
              const historialArray = [];
              historialArray.push(values);
              localStorage.setItem(
                "informacion_cuestionario",
                JSON.stringify(historialArray)
              );

              resetForm({});
              getInformacion();
            }
          } else {
            const historial = localStorage.getItem("informacion_cuestionario");

            if (historial) {
              const historialArray = JSON.parse(historial);
              historialArray.push(values);
              Promise.all(
                historialArray
                  .map((item) =>
                    Contacto({
                      Nombre: item.Nombre,
                      ApellidoPaterno: item.ApellidoPaterno,
                      ApellidoMaterno: item.ApellidoMaterno,
                      Genero: item.Genero,
                      TipoContacto: item.TipoContacto,
                      Otro: item.Otro,
                      TelMovil: item.TelMovil,
                      TelFijo: item.TelFijo,
                      Correo: item.Correo,
                      Empresa: item.Empresa,
                      TipoEmpresa: item.TipoEmpresa,
                      Otro_TipoEmpresa: item.Otro_TipoEmpresa,
                      Giro: item.Giro,
                      Cargo: item.Cargo,
                      Comentarios: item.Comentarios,
                      Festival: item.Festival,
                      User: user_id.data.id,
                    }).then((response) => {
                      return response;
                    })
                  )
                  .then(() => {
                    localStorage.removeItem("informacion_cuestionario");
                    Swal.close();
                    Swal.fire({
                      title: "Contacto",
                      text: "Contacto guardado con exito",
                      icon: "success",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/");
                      }
                    });
                  })
              );
            } else {
              Swal.fire({
                title: "Espere",
                text: "Guardando contacto",
                imageUrl: "logo-login.png",
                imageWidth: 500,
                imageHeight: 100,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                  popup: "swal-loader",
                },
              });
              contacto({
                Nombre: values.Nombre,
                ApellidoPaterno: values.ApellidoPaterno,
                ApellidoMaterno: values.ApellidoMaterno,
                Genero: values.Genero,
                TipoContacto: values.TipoContacto,
                Otro: values.Otro,
                TelMovil: values.TelMovil,
                TelFijo: values.TelFijo,
                Correo: values.Correo,
                Empresa: values.Empresa,
                TipoEmpresa: values.TipoEmpresa,
                Otro_TipoEmpresa: values.Otro_TipoEmpresa,
                Giro: values.Giro,
                Cargo: values.Cargo,
                Comentarios: values.Comentarios,
                Festival: values.Festival,
                User: user_id.data.id,
              });
            }
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <img className="img-fluid" src="logo-login.png" alt="" />
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarColor01"
                ></div>
                <div className="d-flex col-lg-2 col-12 justify-content-end text-center">
                  <button
                    className="btn btn-primary my-2 my-sm-0"
                    type="button"
                    onClick={closeSession}
                  >
                    Cerrar Sesión
                  </button>
                </div>
              </div>
            </nav>
            <section className="vh-100">
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-6 col-lg-6 col-xl-6 col-12 mt-3 text-center">
                    {total !== "" && (
                      <div
                        className="alert alert-success text-center"
                        role="alert"
                      >
                        <strong>
                          <i className="fas fa-check-circle"></i>
                        </strong>
                        <span>
                          Tienes un total de {total} prospectos guardados
                          localmente
                        </span>
                        <br />
                        <button
                          className="btn btn-success mx-auto"
                          onClick={SendInformacion}
                          type="button"
                        >
                          Enviar formularios guardados localmente
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="col-md-10 col-lg-10 col-xl-10">
                    <div className="card mb-3 mt-2 p-4 rounded">
                      <div className="row row-cols-md-2 row-cols-lg-3">
                        <div className="form-outline mb-4">
                          <label className="form-label">Nombre</label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.Nombre}
                            placeholder="Nombre"
                            onChange={(e) =>
                              setFieldValue("Nombre", e.target.value)
                            }
                          />
                          {touched.Nombre && errors.Nombre && (
                            <div>{errors.Nombre}</div>
                          )}
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label">Apelido Paterno</label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.ApellidoPaterno}
                            placeholder="Apellido Paterno"
                            onChange={(e) =>
                              setFieldValue("ApellidoPaterno", e.target.value)
                            }
                          />
                          {touched.ApellidoPaterno &&
                            errors.ApellidoPaterno && (
                              <div>{errors.ApellidoPaterno}</div>
                            )}
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label">Apelido Materno</label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.ApellidoMaterno}
                            placeholder="Apellido Materno"
                            onChange={(e) =>
                              setFieldValue("ApellidoMaterno", e.target.value)
                            }
                          />
                          {touched.ApellidoMaterno &&
                            errors.ApellidoMaterno && (
                              <div>{errors.ApellidoMaterno}</div>
                            )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">Genero</label>
                          <select
                            className="form-control formulario"
                            value={values.Genero}
                            onChange={(e) =>
                              setFieldValue("Genero", e.target.value)
                            }
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="masculino">Masculino</option>
                            <option value="femenino">Femenino</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label">Tipo de contacto</label>
                          <select
                            className="form-control formulario"
                            value={values.TipoContacto}
                            onChange={(e) =>
                              setFieldValue("TipoContacto", e.target.value)
                            }
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="cliente">Cliente</option>
                            <option value="freelance">Freelance</option>
                            <option value="estudiante">Estudiante</option>
                            <option value="prospecto">Prospecto</option>
                            <option value="socio_comercial">
                              Socio comercial
                            </option>
                            <option value="proveedor">Proveedor</option>
                            <option value="lci_corporativo">
                              LCI corporativo
                            </option>
                            <option value="otro">Otro</option>
                          </select>
                        </div>

                        {values.TipoContacto === "otro" && (
                          <div className="form-outline mb-4">
                            <label className="form-label">Otro</label>
                            <input
                              type="text"
                              className="form-control formulario"
                              value={values.Otro}
                              placeholder="Otro"
                              onChange={(e) =>
                                setFieldValue("Otro", e.target.value)
                              }
                            />
                          </div>
                        )}

                        <div className="form-outline mb-4">
                          <label className="form-label">Teléfono Movil</label>
                          <input
                            type="number"
                            className="form-control formulario"
                            value={values.TelMovil}
                            placeholder="Teléfono Movil"
                            onChange={(e) =>
                              setFieldValue("TelMovil", e.target.value)
                            }
                          />
                          {touched.TelMovil && errors.TelMovil && (
                            <div>{errors.TelMovil}</div>
                          )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">Teléfono Fijo</label>
                          <input
                            type="number"
                            className="form-control formulario"
                            value={values.TelFijo}
                            placeholder="Teléfono Fijo"
                            onChange={(e) =>
                              setFieldValue("TelFijo", e.target.value)
                            }
                          />
                          {touched.TelFijo && errors.TelFijo && (
                            <div>{errors.TelFijo}</div>
                          )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">Correo</label>
                          <input
                            type="email"
                            className="form-control formulario"
                            value={values.Correo}
                            placeholder="Correo"
                            onChange={(e) =>
                              setFieldValue("Correo", e.target.value)
                            }
                          />
                          {touched.Correo && errors.Correo && (
                            <div>{errors.Correo}</div>
                          )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">Empresa</label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.Empresa}
                            placeholder="Empresa"
                            onChange={(e) =>
                              setFieldValue("Empresa", e.target.value)
                            }
                          />
                          {touched.Empresa && errors.Empresa && (
                            <div>{errors.Empresa}</div>
                          )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">Tipo de empresa</label>
                          <select
                            className="form-control formulario"
                            value={values.TipoEmpresa}
                            onChange={(e) =>
                              setFieldValue("TipoEmpresa", e.target.value)
                            }
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="productora_audiovisual">
                              Productora audiovisual
                            </option>
                            <option value="productora_eventos_vivo">
                              Productora eventos en vivo
                            </option>
                            <option value="casa_arrendadora">
                              Casa arrendadora
                            </option>
                            <option value="servicios_produccion">
                              Servicios de producción
                            </option>
                            <option value="otro">Otro</option>
                          </select>
                        </div>

                        {values.TipoEmpresa === "otro" && (
                          <div className="form-outline mb-4">
                            <label className="form-label">Otro</label>
                            <input
                              type="text"
                              className="form-control formulario"
                              value={values.Otro_TipoEmpresa}
                              placeholder="Otro"
                              onChange={(e) =>
                                setFieldValue(
                                  "Otro_TipoEmpresa",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )}

                        <div className="form-outline mb-4">
                          <label className="form-label">Giro</label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.Giro}
                            placeholder="Giro"
                            onChange={(e) =>
                              setFieldValue("Giro", e.target.value)
                            }
                          />
                          {touched.Giro && errors.Giro && (
                            <div>{errors.Giro}</div>
                          )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">Cargo</label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.Cargo}
                            placeholder="Cargo"
                            onChange={(e) =>
                              setFieldValue("Cargo", e.target.value)
                            }
                          />
                          {touched.Cargo && errors.Cargo && (
                            <div>{errors.Cargo}</div>
                          )}
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">
                            Comentarios sobre el contacto(Motivo de
                            acercamiento)
                          </label>
                          <input
                            type="text"
                            className="form-control formulario"
                            value={values.Comentarios}
                            placeholder="Comentarios"
                            onChange={(e) =>
                              setFieldValue("Comentarios", e.target.value)
                            }
                          />
                          {touched.Comentarios && errors.Comentarios && (
                            <div>{errors.Comentarios}</div>
                          )}
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label">
                            Festival en donde nos conocio
                          </label>
                          <select
                            className="form-control formulario formulario"
                            value={values.Festival}
                            onChange={(e) =>
                              setFieldValue("Festival", e.target.value)
                            }
                          >
                            <option value="">Selecciona una opción</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label">
                            Autorizo el envio de información a la empresa
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input formulario"
                              type="checkbox"
                              value={values.Autorizo}
                              onChange={(e) =>
                                setFieldValue("Autorizo", e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Autorizo el envio de información a la empresa
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center text-center text-lg-start mt-4 pt-2">
                        {values.Autorizo &&
                          (!internetValidato ? (
                            <button
                              onClick={() => {
                                handleSubmit();
                                settipo(0);
                              }}
                              type="button"
                              className="btn btn-success"
                            >
                              Guardar localmente
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleSubmit();
                                settipo(1);
                              }}
                              type="button"
                              className="btn btn-success"
                            >
                              Enviar informacion
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
