const ENDPOINT = "https://cotizador.lciseguros.com/api/" 

export async function Contacto(data) {
    return fetch(`${ENDPOINT}contacto`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}