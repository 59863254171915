/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Detector } from "react-detect-offline";
import useContacto from "../../hooks/useContacto";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const options = [
  { value: "Festival Guadalajara 2022", label: "Festival Guadalajara 2022" },
  { value: "Festival Guanajuato 2022", label: "Festival Guanajuato 2022" },
];

const loginSchema = Yup.object().shape({
  Nombre: Yup.string().required("Este campo es obligatorio"),
  ApellidoPaterno: Yup.string().required("La Apellido Paterno es obligatorio"),
  ApellidoMaterno: Yup.string().required("La Apellido Materno es obligatorio"),
  TelMovil: Yup.number().required("La Tel Movil es obligatorio"),
  Correo: Yup.string()
    .email("Ingresa una dirección de correo valida")
    .required("La Correo es obligatorio"),
  Puesto: Yup.string().required("La Puesto es obligatorio"),
});

export default function Index() {
  const [total, settotal] = useState(0);
  const [tipo, settipo] = useState("");
  const { error, contacto, message, success } = useContacto();
  const navigate = useNavigate();

  useEffect(() => {
    getInformacion();
    return () => {};
  }, []);

  const getInformacion = () => {
    const valores = localStorage.getItem("historial");

    if (valores) {
      settotal(JSON.parse(valores).length);
    } else {
      settotal(0);
    }
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        title: "Contacto",
        text: "Contacto guardado con exito",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/offline");
        }
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [error, message]);

  const closeSession = () => {
    localStorage.removeItem("usuario");
    localStorage.removeItem("password");
    navigate("/");
  };

  return (
    <>
      <Formik
        initialValues={{
          Nombre: "",
          ApellidoPaterno: "",
          ApellidoMaterno: "",
          TelMovil: "",
          Correo: "",
          Puesto: "",
          Evento: "",
          NoLlamar: "",
          Whatsapp: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(values, { resetForm }) => {
          if (tipo === 0) {
            const historial = localStorage.getItem("historial");
            if (historial) {
              const historialArray = JSON.parse(historial);
              historialArray.push(values);
              localStorage.setItem("historial", JSON.stringify(historialArray));

              resetForm({});
              getInformacion();
            } else {
              const historialArray = [];
              historialArray.push(values);
              localStorage.setItem("historial", JSON.stringify(historialArray));

              resetForm({});
              getInformacion();
            }
          } else {
            const historial = localStorage.getItem("historial");
            const usuario = localStorage.getItem("usuario");
            const password = localStorage.getItem("password");

            if (historial) {
            } else {
              Swal.fire({
                title: "Espere",
                text: "Guardando informacion",
                imageUrl: "logo-login.png",
                imageWidth: 500,
                imageHeight: 100,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                  popup: "swal-loader",
                },
              });
              contacto({
                Nombre: values.Nombre,
                ApellidoPaterno: values.ApellidoPaterno,
                ApellidoMaterno: values.ApellidoMaterno,
                TelMovil: values.TelMovil,
                Puesto: values.Puesto,
                Correo: values.Correo,
                NoLlamar: values.NoLlamar,
                Whatsapp: values.Whatsapp,
                Usuario: usuario,
                Password: password,
              });
            }
          }
        }}
      >
        
        {({ errors, touched, values, setFieldValue, handleSubmit }) => (

          <form onSubmit={handleSubmit}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <img className="img-fluid" src="logo-login.png" alt="" />
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarColor01"
                ></div>
                <div className="d-flex col-lg-2 col-12 justify-content-end">
                  <button
                    className="btn btn-primary my-2 my-sm-0"
                    type="button"
                    onClick={closeSession}
                  >
                    Cerrar Sesión
                  </button>
                </div>
              </div>
            </nav>
            <div className="container">
              <div className="w-100 justify-content-center d-flex">
                <div
                  className="card border-primary mb-3 mt-4"
                  style={{
                    width: "24rem",
                  }}
                >
                  <div className="card-header">
                    Ingresa los datos del prospecto
                  </div>
                  <div className="card-body">
                    <div className="container-fluid h-custom">
                      <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          {total !== "" && (
                            <div className="alert alert-success" role="alert">
                              <strong>
                                <i className="fas fa-check-circle"></i>
                              </strong>
                              <span>
                                Tienes un total de {total} prospectos guardados
                                localmente
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="form-outline mb-4">
                            <label className="form-label">Nombre</label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.Nombre}
                              onChange={(e) =>
                                setFieldValue("Nombre", e.target.value)
                              }
                            />
                            {touched.Nombre && errors.Nombre && (
                              <div className="text-danger">{errors.Nombre}</div>
                            )}
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">
                              Apelido Paterno
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.ApellidoPaterno}
                              onChange={(e) =>
                                setFieldValue("ApellidoPaterno", e.target.value)
                              }
                            />
                            {touched.ApellidoPaterno &&
                              errors.ApellidoPaterno && (
                                <div className="text-danger">
                                  {errors.ApellidoPahterno}
                                </div>
                              )}
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">
                              Apelido Materno
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.ApellidoMaterno}
                              onChange={(e) =>
                                setFieldValue("ApellidoMaterno", e.target.value)
                              }
                            />
                            {touched.ApellidoMaterno &&
                              errors.ApellidoMaterno && (
                                <div className="text-danger">
                                  {errors.ApellidoMaterno}
                                </div>
                              )}
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">Teléfono Movil</label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.TelMovil}
                              onChange={(e) =>
                                setFieldValue("TelMovil", e.target.value)
                              }
                            />
                            {touched.TelMovil && errors.TelMovil && (
                              <div className="text-danger">
                                {errors.TelMovil}
                              </div>
                            )}
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">Correo</label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.Correo}
                              onChange={(e) =>
                                setFieldValue("Correo", e.target.value)
                              }
                            />
                            {touched.Correo && errors.Correo && (
                              <div className="text-danger">{errors.Correo}</div>
                            )}
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">Puesto</label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.Puesto}
                              onChange={(e) =>
                                setFieldValue("Puesto", e.target.value)
                              }
                            />
                            {touched.Puesto && errors.Puesto && (
                              <div className="text-danger">{errors.Puesto}</div>
                            )}
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">No Llamar</label>
                            <input
                              type="checkbox"
                              value={values.NoLlamar}
                              onChange={(e) =>
                                setFieldValue("NoLlamar", e.target.checked)
                              }
                            />
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">
                              Contactar por Whatsapp
                            </label>
                            <input
                              type="checkbox"
                              value={values.Whatsapp}
                              onChange={(e) =>
                                setFieldValue("Whatsapp", e.target.checked)
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-center text-center text-lg-start mt-4 pt-2">
                            <Detector
                              render={({ online }) =>
                                online ? (
                                  <button
                                    onClick={() => {
                                      handleSubmit();
                                      settipo(0);
                                    }}
                                    type="button"
                                    className="btn btn-success"
                                  >
                                    Guardar localmente
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      handleSubmit();
                                      settipo(1);
                                    }}
                                    type="button"
                                    className="btn btn-success"
                                  >
                                    Enviar informacion almacenada
                                  </button>
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
