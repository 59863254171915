/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import useLogin from "../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Detector } from "react-detect-offline";

const loginSchema = Yup.object().shape({
  usuario: Yup.string().required("El usuario es obligatorio"),
  password: Yup.string().required("La contraseña es obligatoria"),
});

export default function Login() {
  const navigate = useNavigate();
  const { error, isLoggedIn, login, message } = useLogin();

  useEffect(() => {
    if (isLoggedIn) {
      Swal.close();
      Swal.fire({
        title: "Bienvenido",
        text: "Ingreso exitoso",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [error, message]);

  const SendOffline = (values) => {
    if (values.usuario === "" && values.password === "") {
      Swal.fire({
        title: "Error",
        text: "Ingresa un usuario y contraseña",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      localStorage.setItem("usuario", values.usuario);
      localStorage.setItem("password", values.password);
      navigate("/offline");
    }
  };

  return (
    <>
      <Detector
        render={({ online }) =>
          !online ? (
            <div className="col-12">
              <div className="alert alert-dismissible alert-danger">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                ></button>
                <span>No hay conexión de internet</span>
              </div>
            </div>
          ) : (
            <div></div>
          )
        }
      />
      <Formik
        initialValues={{
          usuario: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          Swal.fire({
            title: "Espere",
            text: "Validando información",
            imageUrl: "logo-login.png",
            imageWidth: 500,
            imageHeight: 100,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customclassName: {
              popup: "swal-loader",
            },
          });
          login({ email: values.usuario, password: values.password });
        }}
      >
        {({ errors, touched, values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <section className="vh-100">
              <div className="container-fluid h-custom">
                <div
                  className="row d-flex justify-content-center align-items-center"
                  style={{
                    height: "100vh",
                  }}
                >
                  <div className="col-md-8 col-lg-8 col-xl-5">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-center">
                        <p className="lead fw-normal mb-0 me-3">
                          <img
                            className="img-fluid"
                            src="logo-login.png"
                            alt=""
                          />
                        </p>
                      </div>
                      <div className="text-center mt-5">
                        <h3 className="pt-2">Inicio de Sesión</h3>
                      </div>
                      <div className="card-body px-5">
                        <div className="form-outline mb-4">
                          <label className="form-label">Usuario</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.usuario}
                            placeholder={"Ingresa usuario"}
                            onChange={(e) =>
                              setFieldValue("usuario", e.target.value)
                            }
                          />
                        </div>
                        {touched.usuario && errors.usuario && (
                          <div className="text-danger">{errors.usuario}</div>
                        )}

                        <div className="form-outline mb-3">
                          <label className="form-label">Contraseña</label>
                          <input
                            type="password"
                            placeholder={"Ingresa contraseña"}
                            className="form-control"
                            value={values.password}
                            onChange={(e) =>
                              setFieldValue("password", e.target.value)
                            }
                          />
                        </div>
                        {touched.password && errors.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                        <div className="d-flex justify-content-center text-center text-lg-start mt-4 pt-2">
                          <input
                            type="submit"
                            className="btn btn-success rounded"
                            value="Iniciar sesión"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
